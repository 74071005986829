<template>
  <b-modal
    id="update-resell-ticket-modal"
    size="lg"
    centered
    no-close-on-backdrop
    header-bg-variant="light-info"
    hide-footer
    :title="$t('resellTicket.form.title')"
    @shown="onShowUpdateModal"
  >
    <IAmOverlay :loading="false">
      <div>
        <validation-observer ref="refFormObserver">
          <b-form>
            <b-row>
              <b-col md="6">
                <validation-provider
                  #default="validationContext"
                  :name="$t('resellTicket.form.type')"
                  vid="type"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      {{ $t('resellTicket.form.type') }}
                      <span class="text-danger">*</span>
                    </template>
                    <v-select
                      id="type"
                      v-model="resellTicket.type"
                      :placeholder="$t('resellTicket.form.ph.type')"
                      :options="resellTypes"
                      :clearable="false"
                      disabled
                      :class="getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : ''"
                      rules="required"
                    >
                      <template #option="data">
                        <span>{{ $t('resellTicket.type.' + data.label) }}</span>
                      </template>
                      <template #selected-option="data">
                        <span> {{ $t('resellTicket.type.' + data.label) }}</span>
                      </template>
                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('resellTicket.form.source')">
                  <v-select
                    v-model="resellTicket.source"
                    :placeholder="$t('resellTicket.form.source')"
                    :options="sources"
                    :clearable="false"
                    disabled
                    rules="required"
                  >
                    <template #option="data">
                      <span>
                        {{ data.label }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ data.label }}
                      </span>
                    </template>

                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('saleReport.columns.airline')">
                  <v-select
                    v-model="resellTicket.airlineCode"
                    :placeholder="$t('saleReport.columns.airline')"
                    :options="airlineSupports"
                    :reduce="airline => airline.code"
                    label="code"
                    :clearable="false"
                    disabled
                    rules="required"
                  >
                    <template #option="data">
                      <span>
                        {{ data.name }} ({{ data.code }})
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ data.name }} ({{ data.code }})
                      </span>
                    </template>

                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('saleReport.columns.paxType')">
                  <v-select
                    v-model="resellTicket.paxType"
                    class="bg-white"
                    :placeholder="$t('saleReport.columns.paxType')"
                    :options="paxTypes"
                    disabled
                    :clearable="false"
                  >
                    <template #option="data">
                      <span>
                        {{ $t('reservation.' + data.label) }}
                      </span>
                    </template>

                    <template #selected-option="data">
                      <span>
                        {{ $t('reservation.' + data.label) }}
                      </span>
                    </template>

                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group :label="$t('saleReport.columns.paxName')">
                  <b-form-input
                    v-model="resellTicket.paxName"
                    class="bg-white"
                    :placeholder="$t('saleReport.columns.paxName')"
                    disabled
                    @input="$event => {
                      resellTicket.paxName = formatterInput($event);
                    }
                    "
                  />
                  <small class="text-danger">{{ $t('resellTicket.note.paxName') }}</small>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('saleReport.columns.priceTicket')">
                  <IAmInputMoney
                    custom-class="bg-white"
                    :value-money.sync="resellTicket.priceTicket"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group :label="$t('resellTicket.form.price')">
                  <IAmInputMoney
                    :value-money.sync="resellTicket.price"
                    :placeholder="$t('resellTicket.form.ph.price')"
                  />
                  <small class="text-danger">{{ $t('resellTicket.note.price') }} </small>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  :name="$t('resellTicket.form.phone')"
                  vid="phone"
                  rules="required"
                >
                  <b-form-group>
                    <template #label>
                      {{ $t('resellTicket.form.phone') }}
                      <span class="text-danger">*</span>
                    </template>
                    <b-form-input
                      v-model="resellTicket.phone"
                      v-remove-non-numeric-chars.allNumber
                      :name="$t('resellTicket.form.phone')"
                      :state="getValidationState(validationContext) === false ? false : null"
                      :placeholder="$t('resellTicket.form.ph.phone')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <!-- Textarea -->
                <b-form-group
                  :label="$t('resellTicket.form.note')"
                  label-for="note"
                  :placeholder="$t('resellTicket.form.ph.note')"
                >
                  <b-form-textarea
                    id="note"
                    v-model="resellTicket.note"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="12">
                <validation-provider
                  #default="validationContext"
                  rules="required"
                  :name="$t('resellTicket.form.expiredDate')"
                  vid="resellTicket-expiredDate"
                >
                  <b-form-group>
                    <template #label>
                      {{ $t('resellTicket.form.expiredDate') }}
                      <span class="text-danger">*</span>
                    </template>
                    <flat-pickr
                      v-model="resellTicket.expiredDate"
                      class="d-none"
                    />
                    <div
                      class="p-0 form-control"
                      :class="validationContext.errors[0] ? 'is-invalid' : ''"
                    >
                      <flat-pickr
                        id="resellTicket-expiredDate"
                        v-model="resellTicket.expiredDate"
                        :name="$t('resellTicket.form.expiredDate')"
                        :config="{
                          ...configFlatPickr,
                          altInputClass: 'form-control border-transparent px-50 py-0 height-fit',
                        }"
                        :placeholder="$t('topup.placeholderSelectDate')"
                      />
                    </div>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>

                    <small class="text-danger">Anh/Chị kiểm tra ngày hết hạn bảo lưu để chọn ngày hạn đăng bán phù
                      hợp</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr>
            <b-row v-if="isManager">
              <b-col md="6">
                <b-form-group :label="$t('saleReport.columns.bookingCode')">
                  <b-form-input
                    v-model="resellTicket.pnr"
                    disabled
                    :formatter="removeAccentsUpperCaseFormatter"
                  />
                </b-form-group>
              </b-col>
              <b-col sm="6" />
              <b-col>
                <b-form-group
                  :label="$t('resellTicket.form.notePrivate')"
                  label-for="note"
                  :placeholder="$t('resellTicket.form.ph.notePrivate')"
                >
                  <b-form-textarea
                    id="notePrivate"
                    v-model="resellTicket.notePrivate"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>

          <div class="d-flex-center gap-3">
            <b-button
              variant="info"
              @click="handleSubmit"
            >
              {{ $t('save') }}
            </b-button>
            <b-button
              variant="secondary"
              @click="handleCancel"
            >
              {{ $t('cancel') }}
            </b-button>
          </div>
        </validation-observer>
      </div>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import {
  BButton,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BModal,
  BRow,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { cloneDeep, isEqual, transform } from 'lodash'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { removeAccents, removeAccentsUpperCaseFormatter } from '@/@core/comp-functions/forms/formatter-input'
import IAmInputMoney from '@/components/IAmInputMoney.vue'
import IAmOverlay from '@/components/IAmOverlay.vue'
import VueI18n from '@/libs/i18n'

import formValidation from '@core/comp-functions/forms/form-validation'
import { formatCurrency } from '@core/utils/filter'

import useResellTicketHandle from '../useResellTicketHandle'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    IAmOverlay,
    IAmInputMoney,
    BFormInvalidFeedback,
    BButton,
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select'),
  },

  props: {
    resellTicket: {
      type: Object,
      required: true,
    },
    isManager: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  setup(props, { emit }) {
    const {
      resellTypes, paxTypes, airlineSupports, sources, resellTicketStatus,
    } = useResellTicketHandle()
    const oldValue = ref()
    const { refFormObserver, getValidationState } = formValidation()

    function onShowUpdateModal() {
      oldValue.value = cloneDeep(props.resellTicket)
    }
    // methods
    function handleCancel() {
      this.$bvModal.hide('update-resell-ticket-modal')
    }
    function handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          const updateRTReq = transform(props.resellTicket, (result, value, key) => {
            if (!isEqual(value, oldValue.value[key])) {
              result[key] = value
            }
          }, {})
          emit('confirm-update', props.resellTicket.id, updateRTReq)
        }
      })
      this.handleCancel()
    }
    // format input
    function formatterInput(value) {
      const str = removeAccents(value)
      return str.toUpperCase().replace(/\s+/g, ' ')
    }

    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )
    return {
      configFlatPickr,
      sources,
      resellTicketStatus,
      formatCurrency,
      resellTypes,
      airlineSupports,
      paxTypes,
      handleCancel,
      handleSubmit,
      onShowUpdateModal,
      formatterInput,
      refFormObserver,
      getValidationState,
      removeAccentsUpperCaseFormatter,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-control ::v-deep {
  .height-fit {
    height: 37px;
  }
}
</style>
